// === NPM
import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { zodResolver } from "@hookform/resolvers/zod";
import { Help, KeyboardArrowDown } from "@mui/icons-material";
import {
    Button,
    Card,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Skeleton,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import { z } from "zod";
// === LOCAL
import { ReactComponent as Warning } from "@/assets/icons/shared/danger-triangle.svg";
import GenericConfirmDialog from "@/components/generics/dialogs/GenericConfirmDialog";
import IconBannerText from "@/components/generics/IconBannerText";
import GenericAutocomplete from "@/components/generics/inputs/GenericAutocomplete";
import GenericDatePicker from "@/components/generics/inputs/GenericDatePicker";
import GenericSelect from "@/components/generics/inputs/GenericSelect";
import GenericTextField from "@/components/generics/inputs/GenericTextField";
import SecondaryButton from "@/components/styled/SecondaryButton";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import { useDepartments } from "@/context/useDepartmentContext";
import useTimeout from "@/hooks/useTimeout";
import { HttpStatus } from "@/interfaces/global";
import { UserWithoutProperties } from "@/interfaces/user";
import { VaccinationSiteUserType } from "@/interfaces/vaccination";
import { colors, statusColor } from "@/resources/CssConstant";
import { FORM_TEXT, PATTERN, stringRequired } from "@/resources/FormUtils";
import { convertEnumToKeyLabelObject, getEnumKeyByValue, typedObjectKeys } from "@/resources/utils";
import { routerLinks } from "@/routers/RouterConstant";
import { useAuth } from "@/routers/useAuth";
import FarmService from "@/services/FarmService";
import UserService from "@/services/UserService";
import VaccinationService from "@/services/VaccinationService";
import { renderVeterinaryOptions } from "../../../AntimicrobialDisposal/ManualDelivery/containers/FormManager/containers/Form/containers/FormUtils";
import {
    IFarmVaccinationSite,
    IVaccinationSiteForm,
    IVaccinationSiteWorkshop,
    VaccinationSiteOutletContext,
} from "../interface";
import RecapDialog from "./containers/RecapDialog";

export function Form() {
    const auth = useAuth();
    const navigate = useNavigate();
    const { id } = useParams();
    const location = useLocation();

    const { departments } = useDepartments();

    const { horsePower } = useOutletContext<VaccinationSiteOutletContext>();

    const [firstUserType, ...otherUserTypes] = typedObjectKeys(VaccinationSiteUserType);

    const vaccinationSiteSchema = z.object({
        dpeId: stringRequired(),
        veterinaryId: stringRequired(),
        date: stringRequired().refine((value) => DateTime.fromISO(value) <= DateTime.now(), {
            message: "La date du chantier ne peut pas être dans le futur",
        }),
        farmId: stringRequired(),
        workshops: z.array(z.string()).min(1, "Veuillez sélectionner au moins un atelier"),
    });

    const billingSchema = z
        .object({
            userType: z.enum([firstUserType, ...otherUserTypes], {
                required_error: FORM_TEXT.required,
                invalid_type_error: FORM_TEXT.required,
            }),
            distance: z.coerce
                .number({ required_error: FORM_TEXT.required, invalid_type_error: FORM_TEXT.required })
                .int({ message: "La valeur du champ doit être supérieure ou égale à 0." })
                .nonnegative({ message: "La valeur du champ doit être supérieure ou égale à 0." })
                .nullable(),
            plateNumber: z
                .string()
                .regex(new RegExp(PATTERN.plateNumber), FORM_TEXT.plateNumber)
                .transform((value) => value?.toUpperCase())
                .nullable(),
            horsePower: z.string().optional().nullable(),
            interventionDuration: z.number().multipleOf(30).nullable(),
        })
        .superRefine((data, ctx) => {
            if (data.userType === getEnumKeyByValue(VaccinationSiteUserType, VaccinationSiteUserType.VETERINARY)) {
                if (!data.distance && data.distance !== 0)
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        path: ["distance"],
                        message: FORM_TEXT.required,
                    });
                if (!data.plateNumber)
                    ctx.addIssue({ code: z.ZodIssueCode.custom, path: ["plateNumber"], message: FORM_TEXT.required });
                if (!data.horsePower)
                    ctx.addIssue({ code: z.ZodIssueCode.custom, path: ["horsePower"], message: FORM_TEXT.required });
                if (!data.interventionDuration)
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        path: ["interventionDuration"],
                        message: FORM_TEXT.required,
                    });
            }
        });

    const schema = z.intersection(vaccinationSiteSchema, billingSchema);

    type FormSchema = z.infer<typeof schema>;

    const { vehicle } = auth.userInfo;

    const {
        formState: { errors },
        handleSubmit,
        setValue,
        watch,
        register,
        getValues,
        control,
        resetField,
        reset,
    } = useForm<FormSchema>({
        resolver: zodResolver(schema),
        defaultValues:
            id && location.state?.vaccinationSite
                ? {
                      ...location.state.vaccinationSite,
                      workshops: location.state.vaccinationSite.workshops.map((ws) => ws.id),
                  }
                : {
                      dpeId: "",
                      veterinaryId: "",
                      date: "",
                      injectionType: null,
                      userType: null,
                      distance: null,
                      plateNumber: null,
                      horsePower: null,
                      farmId: "",
                      workshops: [],
                      interventionDuration: null,
                  },
    });

    const [veterinaries, setVeterinaries] = useState<UserWithoutProperties[]>([]);
    const [openConfirmCancelDialog, setOpenConfirmCancelDialog] = useState<boolean>(false);
    const [farmSearch, setFarmSearch] = useState<string>(location.state?.vaccinationSite.farmId ?? "");
    const [farms, setFarms] = useState<IFarmVaccinationSite[]>([]);
    const [workshops, setWorkshops] = useState<IVaccinationSiteWorkshop[]>([]);
    const [openRecapDialog, setOpenRecapDialog] = useState<boolean>(false);
    const [vaccinationSite, setVaccinationSite] = useState<IVaccinationSiteForm>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [initWorkshopIds, setInitWorkshopIds] = useState<string[]>(
        location.state?.vaccinationSite.workshops.map((ws) => ws.id) ?? []
    );
    const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
    const [migrated, setMigrated] = useState<boolean>(location.state?.vaccinationSite?.migrated ?? false);

    const farmId = watch("farmId");
    const dpeId = watch("dpeId");
    const userType = watch("userType");
    const date = watch("date");

    const disabled = !!id;
    const farm = farms.find((f) => f.id === farmId);

    const times = useMemo(() => {
        const times = [];
        for (let i = 0; i <= 1440; i += 30) {
            const hours = Math.floor(i / 60);
            const minutes = i % 60;
            const formattedHours = hours.toString().padStart(2, "0");
            const formattedMinutes = minutes.toString().padStart(2, "0");
            times.push({
                label: `${formattedHours}:${formattedMinutes}`,
                value: i,
            });
        }
        return times;
    }, []);

    useTimeout(() => searchFarms(farmSearch, dpeId), [farmSearch]);

    useEffect(() => {
        if (id && !location.state?.vaccinationSite) {
            getVaccinationSite();
        }
        if (farmSearch) searchFarms(farmSearch, dpeId);
    }, []);

    useEffect(() => {
        if (dpeId) {
            getVeterinaries(dpeId);
        }
    }, [dpeId]);

    useEffect(() => {
        if (farmId && date) getWorkshops();
        else {
            setWorkshops([]);
        }
    }, [farmId, date, dpeId]);

    const getVaccinationSite = async () => {
        setLoading(true);
        const res = await VaccinationService.getVaccinationSite(+id);
        if (res.status === HttpStatus.OK) {
            reset({
                ...res.data,
                workshops: res.data.workshops.map((ws) => ws.id),
            });
            setFarmSearch(res.data.farmId);
            searchFarms(res.data.farmId, res.data.dpeId);
            setInitWorkshopIds(res.data.workshops.map((ws) => ws.id));
            setMigrated(res.data.migrated);
        }
        setLoading(false);
    };

    const postVaccinationSite = async () => {
        const res = await VaccinationService.postVaccinationSite(vaccinationSite);
        if (res.status === HttpStatus.CREATED) {
            setOpenRecapDialog(false);
            toast.success("Le chantier a bien été enregistré");
            navigate(routerLinks.iahp.vaccinationSite.view());
        }
    };

    const putVaccinationSite = async () => {
        const res = await VaccinationService.putVaccinationSite(+id, vaccinationSite);
        if (res.status === HttpStatus.OK) {
            setOpenRecapDialog(false);
            toast.success("Le chantier a bien été modifié");
            navigate(routerLinks.iahp.vaccinationSite.view());
        }
    };

    const getWorkshops = async () => {
        const res = await VaccinationService.getVaccinationSiteWorkshops(farmId, date);
        if (res.status === HttpStatus.OK) {
            setWorkshops(res.data);
        } else {
            navigate(routerLinks.iahp.vaccinationSite.view());
        }
    };

    const searchFarms = async (farmId: string, dpeId: string) => {
        if (!farmId) return;
        const payload = {
            page: 0,
            size: 50,
            id: farmId,
            dpeId: dpeId,
        };
        const res = await FarmService.getFarms(payload);
        if (res.status === HttpStatus.OK) {
            setFarms(res.data);
        }
    };

    const getVeterinaries = async (ordinalNumber: string) => {
        const responses = await UserService.getDpeUsers(ordinalNumber);
        if (responses.status === HttpStatus.OK) setVeterinaries(responses.data);
    };
    const handleConfirmCancel = (confirm: boolean) => {
        setOpenConfirmCancelDialog(false);
        if (confirm) {
            navigate(routerLinks.iahp.vaccinationSite.view());
        }
    };

    const handleValid = (confirm: boolean) => {
        setOpenConfirmDialog(false);
        if (confirm) {
            setOpenRecapDialog(true);
            setVaccinationSite(getValues() as IVaccinationSiteForm);
        }
    };

    const handleWorkshop = (event: React.ChangeEvent<HTMLInputElement>) => {
        const tempWorkshopIds = getValues("workshops");
        const { value } = event.target;
        const index = tempWorkshopIds.findIndex((ws) => ws === value);
        index === -1 ? tempWorkshopIds.push(value) : tempWorkshopIds.splice(index, 1);
        setValue("workshops", tempWorkshopIds);
    };

    const workshopTooltip = (workshop: IVaccinationSiteWorkshop): string => {
        if (dpeId !== workshop.sanitaryDpeId) return "Vous n'êtes pas vétérinaire sanitaire de cet atelier";

        const firstSelectedWorkshop = workshops.find((ws) => ws.id === getValues("workshops")?.[0]);

        if (firstSelectedWorkshop && firstSelectedWorkshop.departmentInseeCode !== workshop.departmentInseeCode)
            return "Cet atelier est situé dans un département différent des ateliers sélectionnés";

        if (
            (id && workshop.vaccinationSiteId && +id !== workshop.vaccinationSiteId) ||
            (!id && workshop.vaccinationSiteId)
        ) {
            return "Cet atelier est déjà renseigné dans un autre chantier à la même date";
        }

        if (workshop.hasAttestation) return "Cet atelier possède une attestation de vaccination générée";

        return "";
    };

    const onSubmit = (data: FormSchema) => {
        const removedWorkshopIds = initWorkshopIds.filter((id) => !data.workshops.includes(id));
        if (
            removedWorkshopIds.length > 0 &&
            workshops.filter((ws) => removedWorkshopIds.includes(ws.id)).some((ws) => ws.hasIntervention)
        ) {
            setOpenConfirmDialog(true);
        } else {
            setOpenRecapDialog(true);
            setVaccinationSite(data as IVaccinationSiteForm);
        }
    };

    const renderInformations = () => (
        <Card sx={{ p: 2 }}>
            <StyledCardContent>
                <Grid container spacing={2}>
                    <Grid item md={4} xs={12}>
                        {disabled ? (
                            <GenericTextField
                                label="N° ordre - Nom du DPE"
                                value={`${auth.userInfo.properties.dpes.find((dpe) => dpe.id === dpeId)?.id} - ${
                                    auth.userInfo.properties.dpes.find((dpe) => dpe.id === dpeId)?.name
                                }`}
                                disabled
                            />
                        ) : (
                            <Controller
                                name="dpeId"
                                control={control}
                                render={({ field: { value, onChange }, fieldState: { error } }) => (
                                    <FormControl fullWidth error={!!errors.dpeId} required>
                                        <InputLabel>N° ordre - Nom du DPE</InputLabel>
                                        <Select
                                            label="N° ordre - Nom du DPE"
                                            onChange={(e) => {
                                                onChange(e.target.value);
                                                setValue("veterinaryId", auth.userInfo.id, { shouldValidate: true });
                                                resetField("farmId");
                                                resetField("workshops");
                                                setFarms([]);
                                                setWorkshops([]);
                                                setFarmSearch("");
                                            }}
                                            value={value}
                                            IconComponent={KeyboardArrowDown}
                                            disabled={disabled}
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <Tooltip title="Si vous n’avez aucun DPE disponible, veuillez mettre à jour les informations de votre DPE dans la section “Mes élevages”">
                                                        <Help />
                                                    </Tooltip>
                                                </InputAdornment>
                                            }
                                        >
                                            {auth.userInfo.properties.dpes
                                                .filter((dpe) => dpe.sanitaryDpe)
                                                .map((dpe) => (
                                                    <MenuItem key={dpe.id} value={dpe.id}>
                                                        {`${dpe.id} - ${dpe.name} - ${dpe.postalCode} ${dpe.city}`}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                        <FormHelperText>{error?.message}</FormHelperText>
                                    </FormControl>
                                )}
                            />
                        )}
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Controller
                            name="veterinaryId"
                            control={control}
                            render={({ field: { value, onChange }, fieldState: { error } }) => (
                                <GenericAutocomplete
                                    label="N° ordre - Nom du vétérinaire"
                                    options={veterinaries}
                                    value={veterinaries.find((v) => v.id === value) || null}
                                    onChange={(e) => onChange(e.target.value?.id ?? "")}
                                    required
                                    getOptionLabel={(option) =>
                                        option.id ? `${option.id} - ${option.lastname} ${option.firstname}` : ""
                                    }
                                    renderOption={renderVeterinaryOptions}
                                    error={!!error}
                                    helperText={error?.message}
                                    disabled={disabled}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Controller
                            name="date"
                            control={control}
                            render={({ field: { value, onChange }, fieldState: { error } }) => (
                                <GenericDatePicker
                                    value={value}
                                    name="date"
                                    label="Date d'intervention"
                                    onChange={onChange}
                                    error={!!error}
                                    required
                                    helperText={error?.message}
                                    maxDate={DateTime.now()}
                                    disabled={disabled}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Controller
                            name="userType"
                            control={control}
                            render={({ field: { value, onChange }, fieldState: { error } }) => (
                                <FormControl>
                                    <Typography variant="h5">
                                        Intervention réalisée par{" "}
                                        <Typography component="span" variant="h5" color="error">
                                            *
                                        </Typography>
                                    </Typography>

                                    <RadioGroup
                                        value={value}
                                        onChange={(e) => {
                                            onChange(e.target.value);
                                            resetField("distance");
                                            resetField("interventionDuration");
                                            if (
                                                e.target.value ===
                                                getEnumKeyByValue(
                                                    VaccinationSiteUserType,
                                                    VaccinationSiteUserType.VETERINARY
                                                )
                                            ) {
                                                setValue("plateNumber", vehicle?.plateNumber);
                                                setValue("horsePower", vehicle?.horsePower);
                                            } else {
                                                resetField("plateNumber");
                                                resetField("horsePower");
                                            }
                                        }}
                                    >
                                        {convertEnumToKeyLabelObject(VaccinationSiteUserType).map((item) => (
                                            <FormControlLabel
                                                key={item.key}
                                                value={item.key}
                                                control={<Radio />}
                                                label={item.label}
                                                labelPlacement="end"
                                            />
                                        ))}
                                    </RadioGroup>
                                    {error && <FormHelperText error={!!error}>{error?.message}</FormHelperText>}
                                </FormControl>
                            )}
                        />
                    </Grid>
                </Grid>
            </StyledCardContent>
        </Card>
    );

    const renderBillingInformations = () => (
        <Card sx={{ p: 2 }}>
            <StyledCardContent>
                <Grid container spacing={2}>
                    <Grid item md={4} xs={12}>
                        <TextField
                            {...register("distance")}
                            label="Kilomètres parcourus (aller et retour)"
                            error={!!errors.distance}
                            helperText={errors.distance?.message}
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <TextField
                            {...register("plateNumber")}
                            label="Immatriculation"
                            error={!!errors.plateNumber}
                            helperText={errors.plateNumber?.message}
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Controller
                            name="horsePower"
                            control={control}
                            render={({ field: { value, onChange }, fieldState: { error } }) => (
                                <GenericSelect
                                    value={value || ""}
                                    label="Chevaux fiscaux"
                                    onChange={onChange}
                                    error={!!error}
                                    required
                                    helperText={error?.message}
                                    options={horsePower}
                                    optionsValue="key"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Controller
                            name="interventionDuration"
                            control={control}
                            render={({ field: { value, onChange }, fieldState: { error } }) => (
                                <GenericSelect
                                    value={value || ""}
                                    label="Temps passé (heures et minutes)"
                                    onChange={onChange}
                                    error={!!error}
                                    required
                                    helperText={error?.message}
                                    options={times}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </StyledCardContent>
        </Card>
    );

    const renderWorkshopSelection = () => (
        <Card>
            <StyledCardContent>
                <Stack spacing={4} p={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="farmId"
                                control={control}
                                render={({ field: { value, onChange }, fieldState: { error } }) => (
                                    <GenericAutocomplete
                                        label="Identifiant de l'établissement"
                                        options={farms}
                                        value={farms.find((f) => f.id === value) || null}
                                        onChange={(e) => {
                                            onChange(e.target.value?.id ?? "");
                                        }}
                                        required
                                        inputValue={farmSearch}
                                        onInputChange={(_, newInputValue) => {
                                            setFarmSearch(newInputValue);
                                            resetField("workshops");
                                        }}
                                        noOptionsText={
                                            farmSearch
                                                ? "Aucun résultat"
                                                : "Saisissez des caractères pour lancer la recherche"
                                        }
                                        getOptionLabel={(option) => option.id}
                                        disabled={disabled || !dpeId || !date}
                                        helperText={error?.message}
                                        error={!!error}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <GenericTextField value={farm?.name || ""} label="Nom de l'établissement" disabled />
                        </Grid>
                    </Grid>
                    {farm && workshops.length > 0 && (
                        <Controller
                            name="workshops"
                            control={control}
                            render={({ field: { value }, fieldState: { error } }) => (
                                <Stack spacing={2} sx={{ maxWidth: { xs: "fit-content", md: "30%" } }}>
                                    <Typography variant="bold">Ateliers</Typography>
                                    <FormControl component="fieldset" variant="standard">
                                        <FormGroup sx={{ gap: 1 }}>
                                            {workshops.map((workshop) => (
                                                <Tooltip key={workshop.id} title={workshopTooltip(workshop)}>
                                                    <FormControlLabel
                                                        labelPlacement="start"
                                                        sx={{
                                                            justifyContent: "space-between",
                                                            px: 2,
                                                            ml: 0,
                                                            borderRadius: 10,
                                                            fontWeight: 700,
                                                            border: `1px solid ${
                                                                workshopTooltip(workshop)
                                                                    ? colors.disabled
                                                                    : colors.primaryColor
                                                            }`,
                                                            background: value.find((ws) => ws === workshop.id)
                                                                ? colors.lightGreen
                                                                : "",
                                                        }}
                                                        slotProps={{
                                                            typography: {
                                                                fontWeight: 700,
                                                                color: "primary",
                                                            },
                                                        }}
                                                        control={
                                                            <Checkbox
                                                                value={workshop.id}
                                                                checked={!!value.find((ws) => ws === workshop.id)}
                                                                onChange={handleWorkshop}
                                                                disabled={!!workshopTooltip(workshop)}
                                                            />
                                                        }
                                                        label={`${workshop.id} -  ${workshop.city} - ${
                                                            departments.find(
                                                                (d) => d.inseeCode === workshop.departmentInseeCode
                                                            )?.name ?? ""
                                                        } `}
                                                    />
                                                </Tooltip>
                                            ))}
                                        </FormGroup>
                                    </FormControl>
                                    {error && (
                                        <Typography variant="caption" color="error">
                                            {error?.message}
                                        </Typography>
                                    )}
                                </Stack>
                            )}
                        />
                    )}
                </Stack>
            </StyledCardContent>
        </Card>
    );

    const renderSkeleton = () => (
        <Stack spacing={2} width="100%">
            <Skeleton variant="rounded" height={500} />
            <Skeleton variant="rounded" height={250} />
        </Stack>
    );

    return (
        <>
            {loading ? (
                renderSkeleton()
            ) : (
                <form onSubmit={handleSubmit(onSubmit)} noValidate style={{ width: "100%" }}>
                    <Stack spacing={2} width="100%">
                        <Typography variant="h4">
                            {id ? `Modification du chantier n° ${id}` : "Nouveau chantier"}
                        </Typography>
                        {migrated && (
                            <IconBannerText
                                message="Ce chantier a été généré automatiquement à partir des interventions de vaccination et audits saisis avant le 1er septembre 2024. Les données relatives aux déplacements ont été renseignées sur la base des dernières saisies. Merci de prendre connaissance de l’ensemble des informations notamment celles des déplacements et les modifier au besoin."
                                color={statusColor.warningText}
                                backgroundColor={statusColor.warningBackground}
                                icon={<Warning />}
                            />
                        )}
                        <Typography variant="h5">Informations d'intervention</Typography>

                        {renderInformations()}
                        {userType ===
                            getEnumKeyByValue(VaccinationSiteUserType, VaccinationSiteUserType.VETERINARY) && (
                            <>
                                <Typography variant="h5">Facturation des frais de déplacement</Typography>

                                {renderBillingInformations()}
                            </>
                        )}
                        <Typography variant="h5">Lieu d'intervention</Typography>
                        {renderWorkshopSelection()}
                        <Stack mt={2} direction="row" spacing={2} justifyContent="flex-end" width="100%">
                            <SecondaryButton onClick={() => setOpenConfirmCancelDialog(true)} variant="outlined">
                                Annuler la saisie
                            </SecondaryButton>
                            <Button type="submit" variant="contained" color="primary">
                                Valider
                            </Button>
                        </Stack>
                    </Stack>
                </form>
            )}
            {openConfirmCancelDialog && (
                <GenericConfirmDialog
                    title="Annuler la saisie"
                    message="Êtes-vous sûr de vouloir annuler ? Vous allez perdre toutes les informations saisies."
                    onClose={handleConfirmCancel}
                />
            )}
            {openRecapDialog && (
                <RecapDialog
                    onClose={() => setOpenRecapDialog(false)}
                    onValid={id ? putVaccinationSite : postVaccinationSite}
                    vaccinationSite={vaccinationSite}
                    veterinaries={veterinaries}
                    workshops={workshops}
                    farmName={farm?.name}
                />
            )}
            {openConfirmDialog && (
                <GenericConfirmDialog
                    title="Avertissement"
                    message="Vous venez de désélectionner un ou plusieurs ateliers ayant une intervention de vaccination renseignée. Ces interventions et toutes les données associées vont être supprimées. Voulez-vous vraiment continuer ?"
                    onClose={handleValid}
                />
            )}
        </>
    );
}
